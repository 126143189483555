import React from "react";
import "./Plans.css"

export default function PLans() {
    return(
        <div id="plans" className="plans">
            <div className="left">
                <h1 className="plan-discription">BASIC PLAN</h1>
                <h1 className="price">$ 25</h1>
                <li className="lii">2 hours of excercises</li>
                <li className="lii">Free consultaion to coaches</li>
                
                <li className="lii">Access to The Community
                </li>
                <p className="p">See more benefits ~</p>
                <button className="btn">Join Now</button>
            </div>
            <div className="mid">
            <h1 className="plan-discription">PREMIUM PLAN</h1>
                <h1 className="price">$ 30</h1>
                <li className="lii">5 hour of excercises</li>
                <li className="lii">Free consultaion of Coaches</li>
                
                <li className="lii">Accessto minibar
                </li>
                <p className="p">See more benefits ~</p>
                <button className="btn">Join Now</button>
            </div>
            <div className="right">
            <h1 className="plan-discription">PRO PLAN</h1>
                <h1 className="price">$ 45</h1>
                <li className="lii">8 hours of excercises</li>
                <li className="lii">Consultation of Private Coach</li>
                
                <li className="lii">Free Fitness Merchandises
                </li>
                <p className="p">See more benefits ~</p>
                <button className="btn">Join Now</button>
            </div>
        </div>
    )
}