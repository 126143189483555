import Hero from './Components/Hero';
import React from 'react'
import Programs from './Components/Programs/Programs';
import Programsinfo from './Components/Programsinfo/Programsinfo';
import ProgramsInfoData from './Components/Programsinfo/ProgramsInfoData';
import WhyUs from './Components/Whyus/WhyUs';
import Journey from './Components/Journey/Journey';
import PLans from './Components/Plans/Plans';
import './App.css';
import Test from './Components/Test/Test';

import Join from './Components/Join/Join';
import Footer from './Components/Footer/Footer';
function App() {
  const container =  ProgramsInfoData.map((items) => {
    return(
        <Programsinfo 
        key = {items.id}
        {...items}
        />
    )
})
  // const testData = TestData.map((items) => {
  //   return(
  //     <Test
  //     key = {items.id}
  //     {...items}
  //     />
  //   )
  // })
  return (
    <div className="App">
      <Hero />
      <Programs />
      <div className="program">
      {container}
      </div>
      <WhyUs />
      <Journey />
      <PLans />
      <Test />
      <Join />
      <Footer />
    </div>
  );
}

export default App;
