import React from "react";
import { TestData } from "./TestData";
import "./Test.css"
import left from "/New folder/ReactJs/Gym App/src/assets/leftArrow.png"
import right from "/New folder/ReactJs/Gym App/src/assets/rightArrow.png"

export default function Test(props) {
    const [selected, setSelected] = React.useState(1);
    const dLength = TestData.length;
    return(
        <div id="Testimonials" className="Test">
            <div className="monials">
                <div className="lefft">
                    <h1 className="mon">TESTIMONIALS</h1>
                    <span className="stroke-text">WHAT THEY</span>
                    <br />
                    <span className="stroke--text">SAY ABOUT US</span>
                    <p className="theory">{TestData[selected].review}</p>
                    <div className="spn">
                        <span className="Trainers-name">{TestData[selected].name} - </span>
                        <span className="status">{TestData[selected].status}</span>
                    </div>
                </div>
                <div className="rigght">
                    <div className="rightt">
                        <div className="imagess">
                        <img className="immmg" src={TestData[selected].image} alt="" />
                            <div className="back1">
                            
                            </div>
                            <div className="back2"></div>
                            
                        </div>
                        <div className="butn">
                            <button 
                            onClick={() => {
                                selected===0 ?
                                setSelected(dLength-1) :
                                setSelected((prev) => prev-1)
                            }}
                            className="buttons"><img className="butt1" src={left} alt="" /></button>
                            <button 
                            onClick={() => {
                                selected===dLength -1 ? setSelected(0) : setSelected(prev=> prev+1)
                            }}
                            className="buttonss">
                                <img className="butt2" src={right} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}