import React from "react";
import Header from "./Header/Header";
import kcal from "../assets/kcal.jpg"
import Heero from "../assets/hero_image.png"
import Heart  from "../assets/heart.png"
import Heroo  from "../assets/hero_image_back.png"
import { Link } from "react-scroll";
import CountUp from 'react-countup';

import './Hero.css'
export default function Hero() {
    return(
        <div className="heroSec">
            <div className="hero-Left">
                <Header />
                {/*The best ad section */}
                <div className="the-best-ad">
                    <div 
                    className="the-best-add-animation"></div>
                    <span>THE BEST FITNESS CLUB IN THE TOWN</span>
                </div>
                {/*Hero text */}
                <div className="hero-text">
                    <div className="hero--text">
                        <span className="strok-text">SHAPE </span>
                        <span>YOUR</span>
                    </div>
                    <div className="hero---text">
                        <span>IDEAL BODY</span>
                    </div>
                    
                </div>
                {/*Figures */}
                <div className="figures">
                    <div className="figures1">
                        <span className="figures1-span1">+<CountUp
                            start={0}
                            end={145}
                            duration={3}
                            /> </span>
                        <br />
                        <span className="figures1-span2">EXPERT COACHES</span>
                    </div>
                    <div className="figures2">
                        <span className="figures2-span1">+<CountUp
                            start={0}
                            end={978}
                            duration={3}
                            /></span>
                        <span className="figures2-span2">MEMEBERS JOINED</span>
                    </div>
                    <div className="figures3">
                        <span className="figures3-span1">+<CountUp
                            start={0}
                            end={50}
                            duration={3}
                            /></span>
                        <span className="figures3-span2">FITNESS PROGRAMS</span>
                    </div>
                    <div className="figures4">
                        <div className="figures42">
                        <img className="kcal-img" src={kcal} alt="" />
                        <div className="figures4-spans">
                            <span className="figures4-span1">Calories Burned</span>
                            <span className="figures4-span2">220 kcal</span>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="butttons">
                    <button className="btnn1">Get Started</button>
                    <button className="btnn2">Learn More</button>
                </div>
            </div>
            <div className="Hero-Right">
                <div className="bttn">
                    <button className="Join">
                        <Link
                        to="Join"
                        smooth={true}
                        duration={1000}
                        >Join Now</Link>
                        
                        
                        </button>
                </div>
            </div>
            <div className="heart">
                <img className="heart-img" src={Heart} alt="" />
                <span className="heart-rate">Heart Rate</span>
                <span className="bpm">116 bpm</span>
            </div>
            <div className="heroimg">
                <img className="hero-img" src={Heero} alt="" />
                <img className="hero-back-img" src={Heroo} alt="" />
            </div>
        </div>
    )
}