import React from 'react';
import { Link } from 'react-scroll';
import './Header.css'
import Logo from "../../assets/lo.jpg"
import bars from "../../assets/bars.png"

export default function Header() {
    const [lii, setLii] = React.useState(true);
    
    
    const mobile = window.innerWidth<=768 ? true : false;
    const [menuOpened, setMenuOpened] = React.useState(false);
    function change() {
        setMenuOpened((prev) => {
            return !prev;
        })
        setLii(true)
    }
    let value
    if (mobile === true) {
        value = 
        function changeState() {
            setLii(false)
            setMenuOpened((prev) => {
                return !prev;
            })
        }
    }else {
        value = 0;
    }
    return(
        <div id='Home' className="header">
            <div className="log">
                <img className="logo" src={Logo} alt="" />
                <h1 className="Header-Discription">Universal Fitness Club</h1>
            </div>
            
            
        {(menuOpened === false && mobile === true) ? 
        <div className="im">
            <img onClick={change} style={{width : "1.8rem", height : "1.5rem", paddingTop : "12px", paddingRight : "10px", cursor : "pointer", paddingTop : "20px"}} className="imimage" src={bars} alt="" />
        </div>    
        :
        <div className="secondd">
                {lii && <ul >
                    <li className="li"><Link
                    onClick={value}
                    to="Home"
                    smooth={true}
                    duration={500}
                    >Home</Link></li>
                    
                    
                    <li  className="li"><Link to="program"
                    onClick={value}
                    smooth={true}
                    duration={1000}
                    >Programs</Link></li>
                    <li  className="li"><Link to="whyus"
                    onClick={value}
                    smooth={true}
                    duration={1000}
                    >Why us</Link></li>

                    <li className="li"><Link to="plans"
                    onClick={value}
                    smooth={true}
                    duration={2000}
                    >Plans</Link></li>
                    
                    <li id='testi'  className="li"><Link to="Testimonials"
                    onClick={value}
                    smooth={true}
                    duration={2000}
                    >Testimonials</Link></li>
                </ul>}
            </div>
    }
            
        </div>
    )
}