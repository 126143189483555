import React from "react";
import './Journey.css'

export default function Journey() {
    return(
        <div className="Journey">
            <span className="stroke-text">READY TO 
            <br />  START</span>
            <span className="stroke--text">YOUR 
            <br />  JOURNEY</span>
            <span className="stroke-text">NOW
            <br /> WITH US</span>
        </div>
    )
}