import React from "react";
import './Programs.css'
export default function Programsinfo(props) {
    return(
        <div className="container">
            <h1 className="Heading-discription">{props.heading}</h1>
        <p className="Container-details">{props.detail}</p>
        <span>Join Now ~</span>
        </div>
    )
}