import React, {useRef} from 'react';
import "./Join.css"
import emailjs from 'emailjs-com';
export default function Join() {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_mne36gs', 'template_f1fojm6', form.current, 'K_qzMI8UFvl2JWTew')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    
    return(
        <div id='Join' className="join">
            <div className="leffft">
                <div className="line"></div>
                <span className="stroke-text">READY TO <span className="stroke--text">LEVEL UP 
                <br />
                YOUR BODY </span>WITH US ?</span>
            </div>
            <div className="righttt">
                <div className="mail">
                   <form
                   onSubmit={sendEmail}
                   ref={form}
                   action="
                   ">
                    <input
                    placeholder="Enter your email address here" 
                    className="email" type="email" 
                    name="user_email" 
                    id="" />
                    <button className="btn-email">Join Now</button>
                   </form>
                   
                </div>
            </div>
        </div>
    )
}