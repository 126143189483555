export default[
    {
        id : 1,
        heading : "Strength Training",
        detail: "In this program, you are trained to improve your strength through many exercises."
    },
    {
        id : 2,
        heading : "Cardio Training",
        detail : "In this program, you are trained to do sequential moves in range of 20 until 30 minutes."
    },
    {
        id : 3,
        heading : "Fat Burning",
        detail : "This program is suitable for you who wants to get rid of your fat and lose their weight."
    },
    {
        id : 4,
        heading : "Health Fitness",
        detail : "This programs is designed for those who exercises only for their body fitness not body building."
    }
]