import React from "react";
import './WhyUs.css';
import nike from '../../assets/nb.png'
import nb from '../../assets/nike.png'
import adidas from '../../assets/adidas.png'
import Img from "../../Images/images(1).jpg"
import Images1 from "../../Images/img.jpg";
import imggg from   "../../Images/imgg.jpg"
import immg from "../../Images/immg.jpg"

export default function WhyUs() {
    return(
        <div id="whyus" className="whyUs">
            <div className="images">
                <img className="images1" src={Img} alt="" />
                <div className="images2">
                    <img className="upeer-img" src={Images1} alt="" />
                    <div className="images3">
                        <img className="Left-img" src={imggg} alt="" />
                        <img className="right-img" src={immg} alt="" />
                    </div>
                </div>
            </div>
            <div className="cont">
                        <div className="content">
                            <h1 className="reasons-heading">SOME REASONS</h1>
                            <span className="stroke-text">WHY <span className="stroke--text">CHOOSE US?</span></span>
                            <li>
                            <p className="dicrip">OVER 145+ EXPERT COACHS</p>
                            </li>
                            <li>
                            <p className="dicrip">TRAIN SMARTER AND FASTER THAN BEFORE</p>
                            </li>
                            <li>
                            <p className="dicrip">1 FREE PROGRAM FOR NEW MEMBER</p>
                            </li>
                            <li>
                            <p className="dicrip">RELIABLE PARTNERS</p>
                            </li>
                        </div>
                        <div className="partners">
                            <h1 className="name">OUR PARTNERS</h1>
                            <div className="imagess">
                                <img className="brand-img" src={nike} alt="" />
                                <img className="brand-img" src={nb} alt="" />
                                <img className="brand-img" src={adidas} alt="" />
                            </div>
                        </div>
            </div>
        </div>
    )
}