import React from "react";
import Insta from "../../assets/instagram.png"
import git from "../../assets/github.png"
import linkdin from "../../assets/linkedin.png"
import FooterLogo from "../../assets/lo.jpg"
import { Link } from "react-scroll";
import "./Footer.css"



export default function Footer() {
    return(
        <div className="footer">
            <div className="lane">

            </div>
            <div className="icons">
                <img className="icon-logo" src={git} alt="" />
                <img className="icon-logo" src={Insta} alt="" />
                <img className="icon-logo" src={linkdin} alt="" />
            </div>
            <div className="footerlog">
                <img className="footer-logo" src={FooterLogo} alt="" />
                <h1 className="footer-logo-dic">
                Universal Fitness Club
               
                </h1>
            </div>
            <div className="foot">
                <p className="foot-dis">
                <Link
                    to="Home"
                    smooth={true}
                    duration={1500}
                    >Copyright &copy; ShaeenJuTt H7N </Link>
                
                    
                    </p>
            </div>
        </div>
    )
}