import React from "react";
import './Programs.css'


export default function Program() {
    return(
        <div id="program" className="program">
            {/*Program heading */}
            <div className="Program-heading">
                <span className="stroke-text">EXPLORE OUR </span>
                <span className="stroke--text">PROGRAMS</span>
                <span className="stroke-text">TO SHAPE YOU</span>
            </div>
        </div>
    )
}